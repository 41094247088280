import "./style.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { Carousel } from "react-responsive-carousel";
import blue from "../../img/blue.gif";
import cpu from "../../img/CPUS.svg";
import green from "../../img/green.gif";
import pink from "../../img/pink.gif";
import red from "../../img/red.gif";
import { useTranslation } from "react-i18next";
import white from "../../img/white.gif";
import landing from "../../img/landing_corrected.gif";
import game3dimg from "../../img/game3d.jpg";

function Cpu() {
  const { t, i18n } = useTranslation("common");
  return (
    <div id="game3d" className="game3d">
      <div className="titlesContainer">
        <h2>
          <div>{t("game3d.title1")}</div>
          <div>{t("game3d.title2")}</div>
        </h2>
      </div>
      <div className="lineContainer">
        <div className="lineTitle" />
      </div>
      <div style={{ textAlign: "center" }}>
        <p className="description" style={{ textAlign: "center" }}>
          {t("game3d.description1")}
        </p>
        <img
          className=""
          src={landing}
          style={{
            margin: "auto",
            padding: "1.5vw",
            width: "50rem",
            maxWidth: "100%",
          }}
        />
        <br />
        <img
          className=""
          src={game3dimg}
          style={{
            margin: "auto",
            padding: "1.5vw",
            width: "50rem",
            maxWidth: "100%",
          }}
        />
      </div>
    </div>
  );
}

export default Cpu;
