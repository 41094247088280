import "./style.scss";

import auditors from "../../img/rdauditors.png";
import badgeAws from "../../img/aws.jpeg";
import badgeBinance from "../../img/binance.svg";
import badgeWeb3 from "../../img/web3.jpg";
import badgeWebgl from "../../img/webgl.jpg";
import badgeUnity from "../../img/unity.svg";
import cryptomarketing from "../../img/cryptomarket.jpeg";
import partnership from "../../img/partnership.png";

const badgeSystem = "/img/sponsors/system.jpeg";
const badgeDaddy = "/img/sponsors/daddy.jpeg";
const babylon = "/img/sponsors/babylon.jpg";
const colyseus = "/img/sponsors/colyseus.jpg";

function Partnership() {
  return (
    <div id="partnership" className="partnership">
      <div className="titlesContainer">
        <img src={partnership}></img>
      </div>
      <div className="lineContainer">
        <div className="lineTitle" />
      </div>
      <div className="buttonContainer">
        <a href="https://unity.com/es" target="_blank">
          <img className="badgesImg" src={badgeUnity} alt="logo" />
        </a>
        <a href="https://www.binance.org/en/smartChain" target="_blank">
          <img className="badgesImg" src={badgeBinance} alt="logo" />
        </a>
        <a href="https://web3.foundation/" target="_blank">
          <img width="96px" className="badgesImg" src={badgeWeb3} alt="logo" />
        </a>
        <a href="https://get.webgl.org/" target="_blank">
          <img width="80px" className="badgesImg" src={badgeWebgl} alt="logo" />
        </a>
        <a href="https://cryptomarketing.cl/" target="_blank">
          <img width="160px" className="badgesImg" src={babylon} alt="logo" />
        </a>
        <a href="https://cryptomarketing.cl/" target="_blank">
          <img width="140px" className="badgesImg" src={colyseus} alt="logo" />
        </a>
        <a
          href="https://www.godaddy.com/es/hosting/wordpress-hosting"
          target="_blank"
        >
          <img className="badgesImg wDaddy" src={badgeDaddy} alt="logo" />
        </a>
        <a
          href="https://aws.amazon.com/managed-blockchain/?nc2=h_mo"
          target="_blank"
        >
          <img className="badgesImg wDaddy" src={badgeAws} alt="logo" />
        </a>
        <a href="https://www.rdauditors.com/" target="_blank">
          <img className="badgesImg wDaddy" src={auditors} alt="logo" />
        </a>
        <a href="https://cryptomarketing.cl/" target="_blank">
          <img className="badgesImg wDaddy" src={cryptomarketing} alt="logo" />
        </a>
      </div>
    </div>
  );
}

export default Partnership;
