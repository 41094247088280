import "./style.scss";

import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { Button, Popconfirm, Space, Upload } from "antd";

import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";
import chart from "../../img/tokenomicsChart.svg";
import chartImg from "../../img/grafico.png";
import tokenomics from "../../img/TOKENOMICS.svg";
import { useTranslation } from "react-i18next";
//import yellow from "/img/tech.gif";
const yellow = "/img/tech.gif";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const options = {
  plugins: {
    datalabels: {
      color: "#000000",
      formatter: (value, ctx) => {
        let sum = 0;
        let dataArr = ctx.chart.data.datasets[0].data;
        dataArr.map((data) => {
          sum += data;
        });
        let percentage = ((value * 100) / sum).toFixed(0) + "%";
        return percentage;
      },
    },
  },
};

export const data = {
  plugins: [ChartDataLabels],
  labels: ["Red"],
  datasets: [
    {
      data: [52, 6, 2, 8, 12, 15, 5],
      backgroundColor: [
        "rgba(32, 79, 115, 1)",
        "rgba(244, 240, 253, 1)",
        "rgba(165, 193, 214, 1)",
        "rgba(202, 228, 248, 1)",
        "rgba(119, 167, 203, 1)",
        "rgba(73, 130, 173, 1)",
        "rgba(47, 106, 150, 1)",
      ],
      borderColor: [
        "rgba(32, 79, 115, 1)",
        "rgba(244, 240, 253, 1)",
        "rgba(165, 193, 214, 1)",
        "rgba(202, 228, 248, 1)",
        "rgba(119, 167, 203, 1)",
        "rgba(73, 130, 173, 1)",
        "rgba(47, 106, 150, 1)",
      ],
      borderWidth: 1,
      pointStyle: "circle",
    },
  ],
};

function Tokenomics() {
  const { t, i18n } = useTranslation("common");
  return (
    <div id="tokenomics" className="tokenomics">
      <div className="titlesContainer">
        <img src={tokenomics}></img>
      </div>
      <div className="lineContainer">
        <div className="lineTitle" />
      </div>
      <div className="tokenomicsContainer">
        <div
          className="nftContainer"
          style={{
            width: "99%",
            display: "block",
            textAlign: "center",
          }}
        >
          <img
            className="nft"
            src={yellow}
            alt="nft"
            style={{ margin: "auto", mixBlendMode: "screen", padding: "1.5vw" }}
          />
        </div>
        <div className="chartContainer">
          <img className="" src={chartImg} alt="logo" />
        </div>
        <div className="textContainerToken">
          <div className="tokenomicsSpanTitleContainer">
            <span className="tokenomicsSpanTitle">{t("tokenomics.title")}</span>
          </div>
          <div className="spanContainer">
            <div className="dot1" />
            <span className="tokenomicsSpan">{t("tokenomics.rewards")}</span>
          </div>
          <div className="spanContainer">
            <div className="dot2" />
            <span className="tokenomicsSpan">{t("tokenomics.pool")}</span>
          </div>
          <div className="spanContainer">
            <div className="dot3" />
            <span className="tokenomicsSpan">{t("tokenomics.private")}</span>
          </div>
          <div className="spanContainer">
            <div className="dot4" />
            <span className="tokenomicsSpan">{t("tokenomics.public")}</span>
          </div>
          <div className="spanContainer">
            <div className="dot5" />
            <span className="tokenomicsSpan">{t("tokenomics.devs")}</span>
          </div>
          <div className="spanContainer">
            <div className="dot6" />
            <span className="tokenomicsSpan">{t("tokenomics.marketing")}</span>
          </div>
          <div className="spanContainer">
            <div className="dot7" />
            <span className="tokenomicsSpan">{t("tokenomics.reserv")}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tokenomics;
